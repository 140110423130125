* {
  margin: 0;
  padding: 0;
}

#main {
  color: #fff;
  min-height: 100vh;
  width: 100vw;
  display: flex;
}

#home-main {
  color: #fff;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.obrazek {
  float: left;
  max-width: 50%;
}

.content {
  padding: 16px;
  padding-top: 91px;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  text-align: justify;
}

.content p{
  margin-top: 1%;
  margin-bottom: 1%;
}

.content .gallery{
  background-color: #0000009a;
  margin-right: 2%;
  margin-left: 2%;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  text-align: center;
}

.content .gallery .column{
  display: flex;
  flex-direction: column;
  align-items: first baseline;
  padding: 1%;
  width: 31%;
}

.content .gallery .imgbox {
  width: 100%;
  height: 33.33%;
}

.content .primary {
  background-color: #0000009a;
  padding: 16px;
  margin-right: 2%;
  margin-left: 1%;
  width: 72%;
  height: fit-content;
}

#home {
  align-items: center;
  flex-direction: column;
  height: 100%;
}

#home h2 {
  font-size: 42pt;
}

#home .primary {
  background-color: #0000009a;
  padding: 16px;
  padding-inline: 0px;
  margin-right: 2%;
  margin-left: 2%;
  width: 66.67%;
  height: fit-content;
  text-align: center;
}

.content .secondary {
  background-color: #0000009a;
  padding: 16px;
  margin-right: 1%;
  margin-left: 2%;
  width: 22%;
  height: fit-content;
}

.imgbox {
  width: 33.33%;
  float: right;
  margin: 2%;
  margin-right: 0;
}

.secondary .imgbox {
  width: 50%;
  float: left;
  margin-right: 2%;
  margin-left: 0;
}

.imgbox .etykieta {
  margin: 0%;
  text-align: center;
  font-size: small;
}

.imgbox img {
  max-width: 100%;
}

@media screen and (max-width: 1079px) {
  nav {
      height: fit-content;
      flex-direction: column;
      padding-bottom: 18px;
  }

  .content {
    padding-top: 126px;
  }
  .content .primary {
      width: 63.67%;
  }

  .content .secondary {
      width: 30.33%
  }
  .imgbox{
      width: 50%;
  }
  #catto{
      width: 100%
  }
}

@media screen and (max-width: 770px) {
  #home h2{
    font-size: 32pt;
  }
  #home .primary{
    width: 85%;
  }
  nav .menu {
      width: 100%;
      flex-direction: column;
      align-items: center;
  }

  .content {
      padding-top: 234px;
      flex-direction: column;
  }

  .content .primary {
      width: 95%;
      margin: 0;
      margin: 2% 0;
  }

  .content .secondary {
      width: 95%;
      margin: 0;
      margin: 2% 0;
  }
  #catto{
      width: 50%
  }
  .content .gallery{
    background-color: #0000009a;
    margin-top: 2%;
    margin-bottom: 2%;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .content .gallery .column{
    width: 94%;
  }
}
@media screen and (max-width: 719px) {
  .content {
      padding-top: 16px;
  }
}
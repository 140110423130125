nav {
    position:fixed;
    width: 100%;
    height: 75px;
    background-color: #0000009a;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
}

nav h1 {
    margin: 10px;
    padding: 8px 16px;
    font-weight: bold;
}

nav .menu {
    display: flex;
    text-transform: uppercase;
}

nav .menu a {
    color: #fff;
    cursor: pointer;
}

nav .menu .menu-item {
    padding: 4px 16px;
}

@media screen and (max-width: 1079px) {
    nav {
        height: fit-content;
        flex-direction: column;
        padding-bottom: 18px;
    }
}

@media screen and (max-width: 719px) {
    nav {
        position: relative;
    }
    nav .menu {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }
}